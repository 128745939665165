import Swal from 'sweetalert2'
import { defineStore } from 'pinia'
import { router } from '@inertiajs/vue3'
import { useLoadingStore } from './LoadingStore'
import { useRouterStore } from './RouterStore'
import Spinner from '@components/Spinner.vue'
import { createApp, h, ref } from "vue";


export const useABNStore = defineStore('abnstore', () => {
  const loadingStore = useLoadingStore()
  const routerStore = useRouterStore()

  // Store Models
  const id = ref("")
  const status = ref("")
  const number = ref("")
  const lookup = ref("")

  function resetFinding() {
    Echo.leaveChannel(`abn.${id.value}`)
    id.value = ""
    number.value = ""
    status.value = ""
    lookup.value = ""
  }

  function applyAlert(reference_number) {
    Swal.fire({
      title: 'Existing Application Found',
      html: `Congratulations, your ABN number has been successfully lodged.
    However, your application is under review by ATO, as further checks must be
    undertaken.<br><br>Your reference number is: ${reference_number}<br><br>We
    will continue to monitor the status of your ABN, and as soon as it becomes
    active, we will send you an email to the nominated email address provided to
    give you a record of your ABN number.<br>You will receive an email with this
    information shortly.`,
      icon: 'error',
      confirmButtonText: 'Return Home page',
    })
  }

  function maintenanceAlert() {
    Swal.fire({
      title: 'The government platform is under maintenance',
      html: `The Australian Business Register is currently unavailable.<br><br> We
    sincerely apologise for any inconvenience this may cause. Unfortunately, it
    is out of our control. The government ABR application system is currently
    undergoing maintenance and will remain unavailable until Monday, 8:00 AM.
    <br><br> Rest assured, we will make every effort to proceed with your
    application promptly, within a business day, once the government platform is
    up and running again. <br><br> We will contact you if we require further
    information.`,
      icon: 'error',
      confirmButtonText: 'Submit ABN application',
    }).then((result) => {
      if (result.isConfirmed) {
        routerStore.visit('abn-register')
      }
    })
  }

  function errorAlert() {
    Swal.fire(
      //'There has been an error',
      'System Maintenance',
      //`We are unable to confirm your identity.<br> Please double-check that you
      //have entered your details correctly and resubmit the application. <br> if
      //the error persists, please email us at <a class="text-stone-500"
      //href="mailto:emailing@abn-register.com.au">emailing@abn-register.com.au</a>`,
      `<p class="text-left">We apologise for the inconvenience, but our system is currently
      undergoing maintenance. Your application will be processed within the next
      48 hours. <br> Rest assured, one of our team members will
      email you with any updates on your application. <br> We are
      sorry for any inconvenience this may cause. Thank you for your
      understanding.</p>`,
      'error',
    )
  }

  const abnCanceled = (_number, _status) => {
    number.value = _number
    status.value = _status

    if (Swal.isVisible()) {
      Swal.close();
    }

    if (loadingStore.loading) {
      routerStore.visit('abn-canceled')
    }
  }

  const abnFound = (_number, _status, _lookup) => {
    number.value = _number
    status.value = _status
    lookup.value = _lookup

    if (Swal.isVisible()) {
      Swal.close();
    }

    if (loadingStore.loading) {
      routerStore.visit('details')
    }
  }
  const abnRegistered = (_number, _status, _firstname) => {
    Swal.fire(
      'Your ABN has been registered successfully!',
      ` <br> Name: ${_firstname}
      <br> ABN: ${_number}
        <br> Status: Registered
          <br><br>
        You will receive an email with this information shortly.`,
      'sucess',
    )
  }

  function findingABNAlert() {
    const tempContainer = document.createElement("div");

    const app = createApp({
        render: () => h(Spinner),
    }).mount(tempContainer);

    const spinnerContent = tempContainer.innerHTML;

    Swal.fire({
        title: "Your ABN is being processed",
        html: "<div class='w-full h-36 flex flex-col items-center justify-center gap-4 overflow-hidden'><span>Please be patient</span><br>" + spinnerContent + "</div>",
        allowOutsideClick: false,
        showCancelButton: true,
        showConfirmButton: false,
    }).then((result) => {
        if (result.isDismissed && !number.value) {
            resetFinding();
            loadingStore.setLoading(false);
        }
    });
  }

  function listenAndRecieve() {
    findingABNAlert()
    Echo.channel(`abn.${id.value}`)
      .listen('ABNFinded', ({abn}) => {
        abnFound(abn.number, abn.status, abn.lookup)
        loadingStore.setLoading(false)
      })
      .listen('ABNError', () => {
        resetFinding()
        errorAlert()
        loadingStore.setLoading(false)
      })
      .listen('ABNCanceled', ({abn}) => {
        abnCanceled(abn.number, abn.status)
        loadingStore.setLoading(false)
      })
      .listen('ABNApplying', ({abn}) => {
        resetFinding()
        applyAlert(abn.reference_number)
        loadingStore.setLoading(false)
      })
      .listen('ABNMaintenance', () => {
        resetFinding()
        maintenanceAlert()
        loadingStore.setLoading(false)
      })
      .listen('ABNWrongUserInfo', () => {
        resetFinding()
        errorAlert()
        loadingStore.setLoading(false)
      })
  }

  function findingMode(_id) {
    resetFinding()
    id.value = _id
    listenAndRecieve()
  }

  return {
    abnFound,
    findingMode,
    id,
    lookup,
    number,
    resetFinding,
    status,
  }
}, { persist: true, })
